import React from 'react'
import { Helmet } from 'react-helmet'

import './../assets/css/cgu.css'

const Cgu = () => {
  return (
    <>
      <Helmet>
        <title>CGU | Paramedic</title>
      </Helmet>
      <div className='container content'>
        <h1>Conditions Générales d’Utilisation des applications, des interfaces et du service Paramedic.</h1>
        <h2><span>Article 1. Objet des Conditions Générales d’Utilisation</span></h2>
        <p><span>Les présentes Conditions Générales d’Utilisation s’appliquent à l’ensemble des applications mobiles et interfaces ainsi qu’au service Paramedic. et sont indissociables l’un de l’autre. En téléchargeant ou en utilisant les différentes applications fournies par Paramedic., ces conditions s’appliqueront automatiquement.</span>
        </p>
        <p><span>Les présentes Conditions Générales d’Utilisation sont accessibles sur le Site Paramedic. (www.paramedic.tech). Il est recommandé de les télécharger, d’en garder une copie et d’en prendre connaissance.</span>
        </p>
        <h2><span>Article 2. Définitions</span></h2>
        <p><span><strong>Application :</strong> désigne les différentes  applications mobile développée par ou pour le compte de Paramedic.  permettant de passer commande d’un transport sanitaire. L’Application est téléchargeable gratuitement sur les différents stores (Apple Store et Google Play).</span>
        </p>
        <p><span><strong>Donneur d’Ordre :</strong> désigne l’Utilisateur, qu’il soit personne physique ou morale, particulier ou professionnel, ayant procédé à une Commande.</span>
        </p>
        <p><span><strong>Commande :</strong> désigne toute réservation ou demande immédiate effectuée et validée par le Donneur d&rsquo;Ordre auprès de Paramedic. via l’Application, l&rsquo;Interface Web, le Téléphone ou tout moyen approprié ;</span>
        </p>
        <p><span><strong>Compte :</strong> désigne le compte individuel d’un Utilisateur créé en renseignant ses données à caractère personnel requises et accessible grâce à un Mot de Passe et un Identifiant pour bénéficier des Prestations. Le Compte permet ainsi à l’Utilisateur de visualiser et de gérer les Commandes effectuées;</span>
        </p>
        <p><span><strong>Conditions Générales :</strong> désignent indifféremment les Conditions Générales de Réservation et/ou les Conditions Générales de Transport ainsi que leurs éventuels avenants ;</span>
        </p>
        <p><span><strong>Conditions Générales de Réservation :</strong> désignent les conditions générales de Paramedic. applicables aux Commandes ;</span>
        </p>
        <p><span><strong>Conditions Générales de Transport :</strong> désignent les conditions générales du Transporteur applicables aux Services Transport ;</span>
        </p>
        <p><span><strong>Identifiant :</strong> désigne l’adresse mail choisie par le donneur d’ordre lors de la création de son Compte et lui permettant d’accéder à celui-ci ;</span>
        </p>
        <p><span><strong>Interface Web :</strong> désigne le site Internet accessible depuis l’adresse URL https://do.paramedic.tech et/ou toute URL venant s’y substituer, édité et exploité par Paramedic., permettant à l’Utilisateur de bénéficier des Prestations ;</span>
        </p>
        <p><span><strong>Mot de passe :</strong> désigne une suite de caractères renseignée par l’Utilisateur, constituant son code personnel, exclusif et confidentiel, lors de la création de son Compte et qui, associé à l’Identifiant, lui permet d’accéder aux services Paramedic.</span>
        </p>
        <p><span><strong>Prestations :</strong> désignent les prestations proposées relatives aux Commandes et aux Services Transport ;</span>
        </p>
        <p><span><strong>Service :</strong> désigne l’exploitation par Paramedic., sous la marque « Paramedic. » d’un service de gestion et de régulation des transports sanitaires.</span>
        </p>
        <p><span><strong>Services de Transport :</strong> désignent les services fournis par le Transporteur à savoir l’acheminement de l’Utilisateur vers ou d’un établissement de santé, un domicile, un professionnel médical ou paramédical (par exemple et de façon non exhaustive : médecins, infirmiers, kinésithérapeutes…).</span>
        </p>
        <p><span><strong>Transporteur :</strong> désigne le partenaire ambulancier affilié au réseau Paramedic., qui fournit les Services Transport et avec lequel le Donneur d&rsquo;Ordre contracte pour l’exécution des Services Transport.</span>
        </p>
        <p><span><strong>Utilisateur :</strong> désigne toute personne utilisant l’interface Web et/ou l’Application afin de bénéficier des Prestations. Un Utilisateur peut ainsi être un Donneur d’Ordre, un Transporteur ou un Ambulancier.</span>
        </p>
        <h2><span>Article 3. Les commandes de transport sanitaire</span></h2>
        <p><span>Les Conditions Générales de Réservation s’appliquent à toutes les Commandes passées par un Utilisateur auprès de Paramedic. par le biais d’une interface Web, d’une Application, par téléphone ou par toute autre méthode appropriée.</span>
        </p>
        <p><span>Le fait pour le Donneur d&rsquo;Ordre de passer une Commande implique l’adhésion pleine, entière et irrévocable par ce dernier aux Conditions Générales de Réservation, à l’exclusion de tous autres documents (tels prospectus, catalogues ou site internet, émis par Paramedic.) qui n’ont qu’une valeur indicative.</span>
        </p>
        <p><span>Pour pouvoir passer une Commande, le Donneur d&rsquo;Ordre doit avoir la pleine capacité juridique de contracter. Par ailleurs, l’Utilisateur est responsable des conséquences pécuniaires de l’utilisation de l’interface Web ou des applications faite tant en son nom que pour le compte de tiers, y compris de mineurs.</span>
        </p>
        <p><span>Paramedic. ne pourra être tenu pour responsable des conséquences liées, entre autres causes, au retard ou à la non-disponibilité d’un véhicule sanitaire. L’utilisateur ne pourra donc réclamer de dommages et intérêts en cas de retard ou d’indisponibilité d’un véhicule.</span>
        </p>
        <p><span>Les sociétés d’ambulances ou taxis conventionnés réalisent, pour leur propre compte et sous leurs propres responsabilités, les prestations de transport demandées par le Donneur d’Ordre.</span>
        </p>
        <h3><span>Article 3.1 Modalités de réservation</span></h3>
        <p><span>Afin que sa réservation soit prise en compte, le Donneur d’Ordre doit fournir toutes les informations nécessaires à la Commande, à savoir notamment le type de transport, le nom du patient, le lieu de prise en charge et la destination, les dates et lieux de départ et d’arrivée, étant précisé qu’il appartient au Donneur d’Ordre de s’assurer de la véracité et l’exactitude des informations ainsi communiquées. En cas d’informations erronées de la part de l’Utilisateur, Paramedic. ne pourra être tenu pour responsable de la non-exécution ou la mauvaise exécution de ses obligations au titre de la passation des Commandes.</span>
        </p>
        <p><span>Suite à la requête du Donneur d’Ordre, Paramedic. transmet la demande de transport au transporteur membre de son réseau, sur la base d’informations liés au statut d’un véhicule et de sa disponibilité. Le transporteur est seul responsable de l’acceptation et la validation de la demande. Si le transporteur demandé par l’utilisateur ne peut réaliser le transport, Paramedic. proposera ce transport à un autre transporteur membre du réseau Paramedic.. Le transporteur ayant accepté la demande de transport traitera, dans ce cas, sa Commande selon les conditions et les règles en vigueur régissant les transports sanitaires.</span>
        </p>
        <p><span>Paramedic. transmettra par voie digitale ou orale, la confirmation de la Commande et les informations permettant d&rsquo;effectuer le transport au Transporteur concerné. L’Utilisateur quand à lui pourra consulter le statut du transport au sein de son application ou de son interface Web.</span>
        </p>
        <h3><span>Article 3.2 Modification et annulation des réservations</span></h3>
        <p><span>Le Donneur d’Ordre pourra à tout moment modifier ou annuler un transport commandé via l’Interface Web, l’Application, tant que le patient n’est pas à bord. En cas d’impossibilité, il pourra contacter Paramedic. par téléphone.</span>
        </p>
        <h2><span>Article 4. Données personnelles</span></h2>
        <p><span>Les utilisateurs du service et / ou des applications disposent à tout moment d’un droit d’accès, de modification, de rectification et de suppression des informations personnelles utilisées pour la réalisation du service.</span>
        </p>
        <p><span>Les informations communiquées par l’Utilisateur sur l’interface Web ou par le biais des applications permettent à Paramedic. ainsi qu’aux Transporteurs, de traiter et d’exécuter les Commandes passées.</span>
        </p>
        <p><span>Conformément à l’article 32 de la Loi Informatique et Libertés du 6 janvier 1978 tel que modifié par la loi pour une République Numérique du 8 octobre 2016, Paramedic. informe l’ensemble des utilisateurs du service Paramedic sur l’utilisation qui est faite de leurs données personnelles collectées et de leur durée de conversation ou des critères permettant de déterminer la durée de conservation dans le cadre de l’utilisation de l’interface Web et/ou de l’application et de l’exécution des Commandes.</span>
        </p>
        <p><span>Les données personnelles qui font l’objet d’un traitement par la société Paramedic. sont, par exemple et de façon non exhaustives, les suivantes :</span>
        </p>
        <ul>
          <li><span>pour les Donneurs d’Ordre : Nom, prénom, email, date de naissance, adresse postale, fonction, établissement de santé de rattachement, numéro de téléphone,</span>
          </li>
          <li><span>pour les Transporteurs ou taxis conventionnés : Nom, prénom, email,  date de naissance, adresse postale, fonction, société, numéros de téléphone,</span>
          </li>
          <li><span>pour les Ambulanciers : Nom, prénom, email,  date de naissance, adresse postale, fonction, société, numéros de téléphone,</span>
          </li>
        </ul>
        <p><span>Les données personnelles précitées sont conservées pendant une durée indéterminée. La désinscription du service les supprimera.</span>
        </p>
        <p><span>Par ailleurs, Paramedic. peut utiliser les données personnelles concernant l’Utilisateur afin de lui adresser des informations relatives au service, des supports de formation ou des offres commerciales susceptibles de l’intéresser. L’Utilisateur dispose cependant du droit de s’opposer à la réception par courrier électronique de documents de prospection commerciale non sollicitée, en adressant un email à l’adresse contact@paramedic.tech ou un courrier à l’adresse suivante : Paramedic. 14 rue d’Annam, 75020 Paris.</span>
        </p>
        <h2><span>Article 5.  Disponibilité du service</span></h2>
        <p><span>Le service Paramedic peut occasionnellement faire l’objet d&rsquo;interruption liés à différents événements contingents. Paramedic. met tout en oeuvre pour que l’accès au Service par l’Utilisateur soit ouvert 24H/24 et 7J/7. Toutefois, il est précisé que Paramedic. peut être amené, en raison de ses contraintes d’exploitation, à interrompre, à tout moment, l’accès au Service avec ou sans préavis.</span>
        </p>
        <p><span>D’une façon générale, Paramedic. ne saurait garantir aux Utilisateurs que l’interface Web et les Applications seront exempts d’anomalies, d’erreurs ou de bugs, ni que celles-ci pourront être corrigées, ni que l’interface Web ou les Applications fonctionneront sans interruption ni panne, ni encore qu’elles seront compatibles avec un matériel ou une configuration particulière.</span>
        </p>
        <h2><span>Article 6. Responsabilité de Paramedic.</span></h2>
        <h3><span>Article 6.1   Responsabilité Générale</span></h3>
        <p><span>La responsabilité de Paramedic. ne pourra être engagée en cas de force majeure et plus généralement dans les cas suivants : maintenance informatique, panne informatique ou des réseaux de télécommunication empêchant la disponibilité des services en ligne, grève, manifestation, intempéries, accident de la circulation grave perturbant la circulation régulière, embouteillage d’une ampleur inhabituelle. L’exécution de la prestation de transport est placée sous la responsabilité propre de la société ayant accepté le transport.</span>
        </p>
        <p><span>Paramedic. ne peut être tenu pour responsable des dommages, de quelque nature que ce soit, résultant de l’utilisation ou de l’impossibilité d’utiliser en tout ou partie de l’interface Web et/ou de l’Application. De même, Paramedic. n’est pas responsable du contenu, du fonctionnement et de l’accès aux services et sites Internet auquel l’Interface Web ou l’Application peut renvoyer.</span>
        </p>
        <p><span>La responsabilité de Paramedic. est exclue s’agissant de l’exécution des Services de Transport qui relève exclusivement de la responsabilité du Transporteur, qui est indépendant de Paramedic. dont il n’est pas le sous-traitant.</span>
        </p>
        <p><span>En tout état de cause et dans la mesure où la législation applicable le permettrait, si la responsabilité de Paramedic. devait cependant être engagée, cette dernière ne pourra excéder, tous dommages confondus, le montant de la Commande réglé par le Donneur d’Ordre.</span>
        </p>
        <h3><span>Article 6.2. Responsabilité dans le cadre d’une demande de transport</span></h3>
        <p><span>La responsabilité de Paramedic. se limite à la recherche de Services de Transport disponibles pour le compte du Donneur d&rsquo;Ordre afin d’effectuer la réservation demandée par le Donneur d’Ordre. A ce titre, Paramedic. est soumis à une obligation de moyen s’agissant de la réservation des Services de Transport. Par conséquent, Paramedic. ne peut garantir la disponibilité des Services de Transport et ne peut voir sa responsabilité engagée dans l’hypothèse où Paramedic. n’a pu procéder à la réservation des Services de Transport souhaitée par le Donneur d&rsquo;Ordre faute d’avoir trouvé des Services de Transport disponibles correspondant à ses souhaits.</span>
        </p>
        <p><span>En cas de retard du fait de la société ayant accepté le transport, d’inexécution ou de mauvaise exécution du transport par ce dernier, la responsabilité de Paramedic. ne pourra être engagée.</span>
        </p>
        <p><span>Dans tous les cas, Paramedic. ne pourra être tenue pour responsable des conséquences liées au retard ou à l’absence du véhicule sanitaire à l’heure annoncée ou demandée. De même, Paramedic. ne saurait être tenu responsable des comportement des Ambulanciers ni de leurs faits et gestes.</span>
        </p>
        <h3><span>Article 6.3. Responsabilité dans le cadre du service d’aide à la régulation</span></h3>
        <p><span>En cas d’éventuels préjudices liés à l’utilisation du service, la responsabilité de Paramedic. ne pourra être engagée. Ainsi, Paramedic. ne pourra tenu être responsable, et de façon non limitative, des points suivant :</span>
        </p>
        <ul>
          <li><span>les erreurs de destination ou d’horaires,</span></li>
          <li>
            <span>la mauvaise évaluation du temps de transfert (temps entre le lieu de PEC et le lieu de Rendez-vous),</span>
          </li>
          <li><span>les erreurs liées à la géolocalisation,</span></li>
          <li><span>la non-acceptation d’un transport par un Transporteur,</span></li>
          <li><span>l’absence ou le mauvais paramétrage des notification.</span></li>
        </ul>
        <h2><span>Article 6.4. Non-Respect des Conditions Générales d’Utilisation</span></h2>
        <p><span>Le non-respect d’une quelconque obligation figurant dans les présentes Conditions Générales d’Utilisation entraînera la résiliation immédiate du contrat entre la Société et l’Utilisateur, sans préjudice d’éventuels dommages et intérêts.</span>
        </p>
        <h2><span>Article 7. Propriété intellectuelle – Copyright</span></h2>
        <p><span>Tous les droits de propriété intellectuelle afférents aux Interfaces et aux Application ainsi qu’aux éléments qui y sont accessibles (notamment logos, images, textes, photographies, base de données, logiciels) appartiennent à Paramedic., à l’exception de ceux dont les droits sont détenus par des tiers.</span>
        </p>
        <p><span>Toute reproduction totale ou partielle, modification ou utilisation de ces éléments, pour quelque motif et sur quelque support que ce soit, sans l’accord exprès et préalable de Paramedic., est strictement interdite.</span>
        </p>
        <p><span>L’accès aux applications ou à l’interface Web ainsi que leur utilisation ne confèrent à l’Utilisateur aucun droit sur les droits de propriété intellectuelle y afférents et demeurent la propriété exclusive de Paramedic..</span>
        </p>
        <p><span>Les Utilisateurs du Service ne sont pas autorisés à copier ou modifier l’application, une partie de l’application ou la marque Paramedic. de quelque manière que ce soit. Les Utilisateurs du Service ne sont pas autorisés à extraire le code source de l’application, traduire l’application dans d’autres langues ou d’en créer une version dérivée. L’application elle-même ainsi que les marques commerciales, droits d’auteur, droits de bases de données et autres droits de propriété intellectuelle qui y sont liés appartiennent à Paramedic..</span>
        </p>
        <h2><span>Article 8. Modifications des Conditions Générales d’Utilisation</span></h2>
        <p><span>Les présentes Conditions Générales peuvent être modifiées sans préavis. En cas de modification, les Conditions Générales modifiées entreront en vigueur à compter du moment de leur mise en ligne. Elles seront applicables à toutes les Commandes faites après leur mise en ligne.</span>
        </p>
        <h2><span>Article 9. Utilisation des Applications Mobiles</span></h2>
        <p><span>Il incombe à l’Utilisateur d’assurer la sécurité de son téléphone et de l’accès à l’Application. Nous vous recommandons par conséquent de ne pas débrider votre téléphone (jailbreak ou root).</span>
        </p>
        <p><span>Il est nécessaire que l’application dispose d’une connexion Internet active. Cette connexion peut être Wi-Fi ou fournie par votre opérateur mobile, mais Paramedic ne peut pas assumer la responsabilité si l’application ne fonctionne pas pleinement lorsque vous n’avez pas accès au Wi-Fi ou que votre forfait de données est épuisé.</span>
        </p>
        <p><span>Si vous utilisez l’application en dehors d’une zone disposant de Wi-Fi, souvenez-vous que les conditions d’accord avec votre opérateur mobile s’appliquent toujours. Par conséquent, votre opérateur mobile pourrait vous facturer le coût des données pour la durée de la connexion lorsque vous accédez à l’application, ou vous pourriez encourir des frais d’autres tiers. En utilisant l’application, vous acceptez la responsabilité de ces frais, y compris les frais d’itinérance des données si vous utilisez l’application en dehors de votre territoire (c’est-à-dire votre région ou votre pays) sans désactiver l’itinérance des données. Si vous n’êtes pas la personne qui paie les factures pour l’appareil sur lequel vous utilisez l’application, sachez que nous considérons que la personne qui paie les factures vous a donné la permission d’utiliser l’application.</span>
        </p>
        <p><span>De la même manière, Paramedic. ne peut pas assumer la responsabilité de la façon dont vous utilisez l’application.</span>
        </p>
        <p><span>L’application n’est actuellement disponible que sur Android et iOS ; les spécifications pour chaque système (et pour tous systèmes supplémentaires sur lesquels nous pourrions décider de rendre l’application disponible) peuvent changer, et vous devrez télécharger les mises à jour si vous souhaitez continuer d’utiliser l’application. Paramedic ne promet pas de toujours mettre à jour l’application de sorte qu’elle soit utile pour vous et/ou compatible avec la version d’iOS ou d’Android qui est installée sur votre appareil.</span>
        </p>
        <p><span>Cependant, vous promettez de toujours accepter les mises à jour de l’application lorsqu’elles vous sont proposées. Nous pourrions également vouloir arrêter de fournir l’application, et nous pouvons mettre fin à son utilisation à tout moment sans vous en avertir. Sauf information contraire de notre part, au moment de la fin des services, (a) les droits et les licences qui vont sont accordés par ces conditions prendront fin ; (b) vous devrez arrêter d’utiliser l’application et (si nécessaire) la supprimer de votre appareil.</span>
        </p>
        <h2><span>Article 10. Réclamation</span></h2>
        <p><span>Le Donneur d&rsquo;Ordre peut adresser ses éventuelles réclamations par voie postale ou électronique en rappelant la référence (Numéro Unique de Transport), la date de la Commande et ses principales caractéristiques.</span>
        </p>
        <p><span>Toute réclamation devra parvenir à Paramedic. dans un délai de deux mois maximum à compter de la date d’exécution du Transport.</span>
        </p>

      </div>
    </>
  )
}

export default Cgu
